<template>
    <div class="respective_content" id="workout_index">
        <div class="acton_header">
            <div class="left_section">
                <h2>Workout Programs</h2>
                <div class="search_area">
                    <input type="text" placeholder="Search" @input="isTyping = true" v-model.trim="search"/>
                    <button class="search_btn">
                        <i class="fas fa-search"></i>
                    </button>
                </div>
                <!-- <button type="button" class="video_btn" @click="tutorial = true;"><i class="fas fa-play"></i></button>
                <button type="button" class="add_btn pointer" v-if="companyUserCan('create', 'workouts')" @click="newWorkout = true"><i class="fas fa-plus"></i>Add A Workout</button> -->
            </div>
            <div class="right_section">
                <ul>
                    <li @click="assetFolder = !assetFolder" v-tooltip="assetFolder ? 'Close Folders' : 'Open Folders'" position="bottom"><img src="@/assets/images/folder4.svg"></li>
                    <li @click="isLibrary = true;" v-tooltip="`Manage Exercise Library`" position="bottom"><img src="@/assets/images/library2.svg"></li>
                    <li @click="sortWorkout = true;" v-tooltip="`Sort Workouts Dashboard`" position="bottom"><img src="@/assets/images/slider3.svg"></li>
                    <li @click="importWorkout = true;" v-tooltip="`Import a shared workout`" position="bottom"><img src="@/assets/images/import.svg"></li>
                    <li @click="manageCategories = !manageCategories" v-tooltip="`Manage Categories`" position="bottom"><img src="@/assets/images/smart.svg"></li>
                    <li class="add_btn light pointer" v-if="companyUserCan('create', 'workouts')" @click="newWorkout = true"><i class="fas fa-plus-circle"></i>Create A Workout</li>
                </ul>
            </div>
        </div>
        <div class="result_wpr new">
            <div class="actions">
                <ul class="left">
                    <li class="p-0" v-show="selectedWorkouts.length || showContent">
                        <label for="check" class="checkbox">
                            <h5 class="p-0">{{ selectedWorkouts.length ? `${selectedWorkouts.length} Selected` : `Select Workouts for bulk action`}}</h5>
                        </label>
                    </li>
                    <li class="optionDrops contacts-tabs" @click="bulkOpt = !bulkOpt" v-if="selectedWorkouts.length" v-click-outside="closeBulkActionDropdown">
                        Bulk Action<i class="fas fa-angle-down"></i>
                        <div class="dropdown_wpr" :class="bulkOpt ? 'active' : ''">
                            <ul>
                                <li @click="handleAssignContacts(selectedWorkouts)">Assign Contacts</li>
                                <li @click="handleUnassignContacts(selectedWorkouts)">Unassign Contacts</li>
                                <li class="danger"  @click="handleDeleteWorkout(selectedWorkouts)">Delete</li>
                            </ul>
                        </div>
                    </li>
                    <li class="optionDrops contacts-tabs tutorial_btn" @click="tutorialList = !tutorialList;" v-click-outside="closeTutorialListDropdown">
                        <label>Tutorials</label><img src="@/assets/images/play.svg">
                        <div class="dropdown_wpr" :class="tutorialList ? 'active' : ''">
                            <ul>
                                <li @click="tutorial = true;">How to use the workouts feature</li>
                            </ul>
                        </div>
                    </li>
                    <!-- <li class="search_area ml-auto" :class="{'active': searchField}">
                        <input type="text" placeholder="Search" @input="isTyping = true" v-model.trim="search"/>
                        <button class="search_btn" @click="searchField = !searchField;">
                        <i class="fas fa-search"></i>
                        </button>
                    </li> -->
                </ul>
                <ul class="right">
                    <li class="optionDrops contacts-tabs" @click="actionList = !actionList"  v-click-outside="closeSortingDropdown">
                        {{ filterTitle }} <i class="fas fa-angle-down"></i>
                        <div class="dropdown_wpr" :class="actionList ? 'active' : ''">
                            <ul>
                                <li v-for="(filter, f) in filters" :key="f" :class="{'active' : filter.title == filterTitle}" @click="sortingFilter(filter)">
                                    {{ filter.title }}
                                </li>
                            </ul>
                        </div>
                    </li>
                    <li class="optionDrops sort_list" @click="togglePerPageFilter()" v-click-outside="closePerPageDropdown">
                        Show {{ params.per_page }} <i  class="fas fa-angle-down"></i>
                        <page-filter v-model="perPageFilter" :type="1" :is-dropdown="true" ref="workout-per-page-filter" />
                    </li>
                    <li class="filter_btn" @click="mobile_filter = true;">
                        <span></span>
                        <span></span>
                        <span></span>
                    </li>
                </ul>
            </div>
            <ul class="tab_sec">
                <li :class="tab === 'workouts' ? 'active' : ''" @click="switchTab('workouts')">My Workouts <sup>&nbsp;</sup></li>
                <li :class="tab === 'plan' ? 'active' : ''" @click="switchTab('plan')">My Programs <sup>&nbsp;</sup><!--<sup class="workout-plan-beta">Beta</sup>--></li>
            </ul>
            <div class="listing_loader" v-if="workoutLoader"><quote-loader /></div>
            <div class="action_content" v-show="!workoutLoader">
                <asset-folder v-model="assetFolder" title="Workout" asset-type="workouts" :folder-params="folderParams" :refresh-folder="refreshFolder" :asset-folder-watcher="assetFolderWatcher" :is-last-breadcrumb="isLastBreadcrumb" :update-breadcrumb="updateFolderBreadcrumb" :section-breadcrumb="folderBreadcrumb['workouts']"/>
                <ul class="playbook_list">
                    <li v-for="(workout, w) in workouts.data" :key="w">
                        <div class="plybook_item" @mouseenter="showAction(true)" @mouseleave="showAction(false)">
                            <div class="item_img" :class="{ active : selectedWorkouts.includes(workout.id) }">
                                <img v-if="workout.thumb" :src="workout.thumb" class="img">
                                <img v-else src="@/assets/images/thumb/default-progress.svg" class="img">
                                <span v-if="companyUserCan('update', 'workouts')" class="replace_img" @click="editThumbnail = true; activeWorkout = workout;">
                                    <img src="@/assets/images/image.svg">
                                </span>
                                <ul class="statistics" v-if="workout.is_private">
                                    <li>
                                        <label :for="`workout-${workout.id}`" class="checkbox">
                                        <input type="checkbox" :id="`workout-${workout.id}`" :value="workout.id" v-model="selectedWorkouts" hidden>
                                        <span><i class="fas fa-check"></i></span>
                                        </label>
                                    </li>
                                    <li><span class="pp-label">{{ workout.is_private ? 'Private' : 'Public'}}</span></li>
                                    <li>Assigned: {{ workout.assign_count ? workout.assign_count : 0 }}</li>
                                    <li>Completed: {{ workout.responses_count }}</li>
                                    <li>Completion Rate: {{ workout.completion_rate }}%</li>
                                    <li>
                                        Overall Rating:
                                        <star-rating
                                            :increment="0.01"
                                            :max-rating="5"
                                            :rating="workout.ratings"
                                            active-color="#2f7eed"
                                            active-border-color="#2f7eed"
                                            :border-width="1"
                                            border-color="#c5c5c5"
                                            :show-rating="false"
                                            :star-size="8"
                                            :read-only="true"
                                            :rounded-corners="true"
                                            inactive-color="#c5c5c5"
                                        ></star-rating>
                                    </li>
                                </ul>
                                <ul class="statistics" v-else>
                                    <li>
                                        <label :for="`workout-${workout.id}`" class="checkbox">
                                        <input type="checkbox" :id="`workout-${workout.id}`" :value="workout.id" v-model="selectedWorkouts" hidden>
                                        <span><i class="fas fa-check"></i></span>
                                        </label>
                                    </li>
                                    <li><span class="pp-label">{{ workout.is_private ? 'Private' : 'Public'}}</span></li>
                                    <li>Unique Views: {{ workout.views_count }}</li>
                                    <li>Opt-ins: {{ workout.optins_count }}</li>
                                    <li>Opt-in Rate: {{ workout.optins_rate }}%</li>
                                    <li>Completions: {{ workout.responses_count }}</li>
                                    <li>
                                        Overall Rating:
                                        <star-rating
                                            :increment="0.01"
                                            :max-rating="5"
                                            :rating="workout.ratings"
                                            active-color="#2f7eed"
                                            active-border-color="#2f7eed"
                                            :border-width="1"
                                            border-color="#c5c5c5"
                                            :show-rating="false"
                                            :star-size="8"
                                            :read-only="true"
                                            :rounded-corners="true"
                                            inactive-color="#c5c5c5"
                                        ></star-rating>
                                    </li>
                                </ul>
                            </div>
                            <div v-tooltip="workout.name">
                                <a v-if="companyUserCan('update', 'workouts')" class="item_ttl pointer" @click="toggleWorkoutWizard(workout)">{{workout.name}}</a>
                                <a v-else class="item_ttl pointer">{{workout.name}}</a>
                            </div>
                            <div class="item_info">
                                <div class="member pointer" @click="manageContact = true; activeWorkout = workout;" v-tooltip="`Clients Assigned to this Workout`">
                                    <img src="@/assets/images/member.svg">
                                    {{ workout.assign_count ? workout.assign_count : 0 }} Assigned
                                </div>
                                <ul>
                                    <li @click="handleAnalytics(workout)"><span v-tooltip="`Open Analytics`"><i class="fas fa-chart-line"></i></span></li>
                                    <li @click="workoutLink = true; activeWorkout = workout;"><span v-tooltip="`Live Workout Link`"><i class="fas fa-link"></i></span></li>
                                    <li @click="moreOption(workout.id)">
                                        <span v-tooltip="`Additional Actions`"><i class="fas fa-ellipsis-v"></i></span>
                                        <ul class="row_option mr-4 py-1" :class="{'active' : moreOptionDropdown ==  workout.id}">
                                            <li v-if="companyUserCan('update', 'workouts')" @click="toggleWorkoutWizard(workout)">Edit</li>
                                            <li v-if="companyUserCan('update', 'workouts')" @click="renameWorkout = true; activeWorkout = workout;">Rename</li>
                                            <li @click="manageContact = true; activeWorkout = workout;">Manage</li>
                                            <li @click="workoutPreview = true; activeWorkout = workout;">Preview</li>
                                            <li @click="handleAnalytics(workout)">Analytics</li>
                                            <li @click="handleShareWorkout(workout);" v-if="!workout.is_shared_with_contact">Share</li>
                                            <li v-if="companyUserCan('update', 'workouts')" @click="assignedPlans = true; activeWorkout = workout;">Plans</li>
                                            <li @click="handleAssignContacts([workout.id])">Assign Contacts</li>
                                            <li v-if="companyUserCan('create', 'workouts')" @click="handleDuplicateWorkout([workout.id])">Duplicate</li>
                                            <li v-if="companyUserCan('delete', 'workouts')" class="danger" @click="handleDeleteWorkout([workout.id])">Delete</li>
                                        </ul>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </li>
                </ul>
                <!-- <div class="empty_section" v-if="!workouts.total">
                    <img src="@/assets/images/curve_arrow.svg" alt="" class="arrow">
                    <h1>Looks like you don't have any Workouts yet. Click the play button to get started.</h1>
                </div> -->
                <div class="empty_box" v-if="!workouts.total">
                    <img src="@/assets/images/empty_state.svg">
                    <h4>Looks like you don't have any Workouts yet. Click the play button to get started.</h4>
                </div>
            </div>
            <div class="list_footer">
                <ul>
                    <li>{{ workouts.from ? workouts.from : 0 }} - {{ workouts.to ? workouts.to : 0 }} of {{ workouts.total ? workouts.total : 0 }}</li>
                </ul>
            </div>
            <div class="pagination" v-show="workouts.total">
                <pagination v-model="page" :pages="workouts.last_page" @update:modelValue="handlePagination" />
            </div>
        </div>
        <div class="header_filter" :class="{'show' : mobile_filter}">
            <button class="close_btn" @click="mobile_filter = false;"><i class="fas fa-times"></i></button>
            <div class="top_area">
                <h3>Actions</h3>
                <ul>
                    <li @click="assetFolder = !assetFolder" v-tooltip="assetFolder ? 'Close Folders' : 'Open Folders'" position="bottom"><img src="@/assets/images/folder4.svg"></li>
                    <li @click="isLibrary = true;" v-tooltip="`Manage Exercise Library`" position="bottom"><img src="@/assets/images/library2.svg"></li>
                    <li @click="sortWorkout = true;" v-tooltip="`Sort Workouts Dashboard`" position="bottom"><img src="@/assets/images/slider3.svg"></li>
                    <li @click="importWorkout = true;" v-tooltip="`Import a shared workout`" position="bottom"><img src="@/assets/images/import.svg"></li>
                </ul>
            </div>
            <div class="action_area">
                <h3 class="sub_heading2">Filters</h3>
                <div class="search_area">
                    <input type="text" placeholder="Search" @input="isTyping = true" v-model.trim="search"/>
                    <button class="search_btn">
                        <i class="fas fa-search"></i>
                    </button>
                </div>
                <ul>
                    <li v-show="selectedWorkouts.length || showContent">
                        <label for="check" class="checkbox">
                            <h5 class="p-0">{{ selectedWorkouts.length ? `${selectedWorkouts.length} Selected` : `Select Workouts for bulk action`}}</h5>
                        </label>
                    </li>
                    <li class="optionDrops contacts-tabs" @click="bulkOpt2 = !bulkOpt2" v-if="selectedWorkouts.length" v-click-outside="closeBulkActionDropdown2">
                        Bulk Action<i class="fas fa-angle-down"></i>
                        <div class="dropdown_wpr" :class="bulkOpt2 ? 'active' : ''">
                            <ul>
                                <li @click="handleAssignContacts(selectedWorkouts)">Assign Contacts</li>
                                <li @click="handleUnassignContacts(selectedWorkouts)">Unassign Contacts</li>
                                <li class="danger" @click="handleDeleteWorkout(selectedWorkouts)">Delete</li>
                            </ul>
                        </div>
                    </li>
                    <li class="optionDrops contacts-tabs" @click="actionList2 = !actionList2"  v-click-outside="closeSortingDropdown2">
                        {{ filterTitle }} <i class="fas fa-angle-down"></i>
                        <div class="dropdown_wpr" :class="actionList2 ? 'active' : ''">
                            <ul>
                                <li v-for="(filter, f) in filters" :key="f" :class="{'active' : filter.title == filterTitle}" @click="sortingFilter(filter)">
                                    {{ filter.title }}
                                </li>
                            </ul>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>

    <video-tutorial v-model="tutorial" :tutorial-video="tutorialVideo"></video-tutorial>
    <new-workout v-model="newWorkout" :refresh-asset="refreshWorkout" />
    <workout-wizard v-model="workoutWizard" :refresh-asset="refreshWorkout" />
    <exercise-library v-model="isLibrary"/>
    <rename-workout v-model="renameWorkout" :workout="activeWorkout" :refresh-workout="refreshWorkout" />
    <edit-thumbnail-asset v-model="editThumbnail" title="Workout" :selected-asset="activeWorkout" :refreshAsset="refreshWorkout" />
    <share-component v-model="shareWorkout" title="Workout" :selected-asset="activeWorkout" />
    <asset-link v-model="workoutLink" title="Workout" :selected-asset="activeWorkout" />
    <sort-asset v-model="sortWorkout" title="Workout" asset-type="workouts" :update-sorting="sortingFilter" />
    <import-share-link v-model="importWorkout" title="Workout" :refresh-asset="refreshWorkout" />
    <assigned-plans v-model="assignedPlans" :selected-workout="activeWorkout" />
    <assign-contact v-model="assignContact" :ids="workoutIds" :refresh-asset="refreshWorkout" />
    <unassign-contact v-model="unassignContact" :ids="workoutIds" :refresh-asset="refreshWorkout" :is-plan="false" />
    <manage-contact v-model="manageContact" :selected-asset="activeWorkout" :refresh-asset="refreshWorkout" />
    <workout-analytics v-model="showAnalytics" :workout="activeWorkout"/>
    <preview-workout v-model="workoutPreview" :workout="activeWorkout" />
    <workout-categories v-model="manageCategories" />
</template>

<script>
    import { defineAsyncComponent } from 'vue'
    import { mapState, mapActions, mapMutations, mapGetters } from 'vuex'

    const WorkoutWizard = defineAsyncComponent(() => import('@/pages/workout/components/Wizard'))
    const ExerciseLibrary = defineAsyncComponent(() => import('@/pages/workout/components/ExerciseLibrary'))
    const RenameWorkout = defineAsyncComponent(() => import('@/pages/workout/components/RenameWorkout'))
    const ShareComponent = defineAsyncComponent(() => import('@/components/ShareComponent'))
    const AssetLink = defineAsyncComponent(() => import('@/components/AssetLink'))
    const NewWorkout = defineAsyncComponent(() => import('@/pages/workout/components/NewWorkout'))
    const EditThumbnailAsset = defineAsyncComponent(() => import('@/components/EditThumbnailAsset'))
    const SortAsset = defineAsyncComponent(() => import('@/components/SortAsset'))
    const ImportShareLink = defineAsyncComponent(() => import('@/components/ImportShareLink'))
    const AssignedPlans = defineAsyncComponent(() => import('@/pages/workout/components/AssignedPlans'))
    const AssignContact = defineAsyncComponent(() => import('@/pages/workout/components/AssignContact'))
    const UnassignContact = defineAsyncComponent(() => import('@/pages/workout/components/UnassignContact'))
    const ManageContact = defineAsyncComponent(() => import('@/pages/workout/components/ManageContact'))
    const WorkoutAnalytics = defineAsyncComponent(() => import('@/pages/workout/components/WorkoutAnalytics'))
    const PreviewWorkout = defineAsyncComponent(() => import('@/pages/workout/components/PreviewWorkout'))
    const AssetFolder = defineAsyncComponent(() => import('@/components/AssetFolder'))
    const WorkoutCategories = defineAsyncComponent(() => import('@/pages/workout/components/Categories'))

    import Swal from 'sweetalert2'
    import Helper from '@/utils/Helper'
    import Toastr from '@/utils/Toastr'
    import StarRating from 'vue-star-rating'

    export default {
        name: "Workouts Index",

        data () {
            return {
                tutorialList: false,
                tutorial: false,
                tutorialVideo: 'https://www.loom.com/embed/8c32baad69e64e5da5366f16618162d9?hide_owner=true&hide_share=true&hide_title=true&hideEmbedTopBar=true',
                assetFolder: false,
                actionList: false,
                actionList2: false,
                shareWorkout: false,
                bulkOpt: false,
                bulkOpt2: false,
                workoutWizard: false,
                isLibrary: false,
                searchField: false,
                perPageFilter: 6,
                showContent: false,
                selectedWorkouts: [],
                filterTitle: 'Custom Arrangement',
                filters: [
                    { title: 'Custom Arrangement', field: 'order', order: 'desc'},
                    { title: 'A-Z', field: 'name', order: 'asc'},
                    { title: 'Z-A', field: 'name', order: 'desc'},
                    { title: 'Public', field: 'public', order: 'desc'},
                    { title: 'Private', field: 'private', order: 'desc'},
                    { title: 'Date Created', field: 'id', order: 'desc'},
                    { title: 'Date Modified', field: 'updated_at', order: 'desc'},
                ],
                newPage: false,
                folder: 'main',
                showFolder: false,
                fullPreview: false,
                moreOptionDropdown: false,
                isMoreOption: false,
                renameWorkout: false,
                activeWorkout: {},
                workoutLink: false,
                page: 1,
                isTyping: false,
                search: '',
                newWorkout: false,
                editThumbnail: false,
                sortWorkout: false,
                importWorkout: false,
                assignedPlans: false,
                assignContact: false,
                unassignContact: false,
                workoutIds: [],
                manageContact: false,
                showAnalytics: false,
                workoutPreview: false,
                assetFolderWatcher: 0,
                isLastBreadcrumb: false,
                isArchived: 0,
                folderParams: {
                    archived: 0,
                    parent_id: 0,
                },
                mobile_filter: false,
                manageCategories: false,
            }
        },

        props: {
            tab: {
                type: String,
                Default: 'Workout'
            },
            switchTab: {
                type: Function,
                Default: () => {},
            },
        },

        components:{
            WorkoutWizard,
            ExerciseLibrary,
            RenameWorkout,
            ShareComponent,
            AssetLink,
            NewWorkout,
            EditThumbnailAsset,
            SortAsset,
            ImportShareLink,
            AssignedPlans,
            AssignContact,
            UnassignContact,
            ManageContact,
            WorkoutAnalytics,
            PreviewWorkout,
            AssetFolder,
            StarRating,
            WorkoutCategories,
        },

        watch: {
            fullPreview(val){
                if (val) {
                    document.body.classList.add('modal-open');
                } else {
                    document.body.classList.remove('modal-open');
                }
            },

            perPageFilter (perPage) {
                const vm = this;
                vm.page  = 1;

                vm.setDashboardParams({ key: 'page', value: 1 });
                vm.setDashboardParams({ key: 'per_page', value: perPage });

                vm.getWorkouts(vm.params);
            },

            search (val) {
                const vm = this;

                vm.setDashboardParams({ key: 'search', value: val });

                setTimeout(() => {
                    vm.isTyping = false;
                }, 1500);
            },

            isTyping (val) {
                const vm = this;

                if (!val) {
                    if (vm.params.search != null) {
                        if (vm.params.search.length >= 2) {
                            vm.page  = 1;

                            vm.setDashboardParams({ key: 'page', value: 1 });
                            vm.getWorkouts(vm.params);
                        }
                        if (vm.params.search.length === 0) {
                            vm.page  = 1;

                            vm.setDashboardParams({ key: 'page', value: 1 });
                            vm.getWorkouts(vm.params);
                        }
                    }
                }
            },

            assetFolder (val) {
                const vm = this;

                vm.setIsAssetFolder(val);
            },
        },

        computed: {
            ...mapState ({
                params: state => state.workoutModule.workoutParams,
                workouts: state => state.workoutModule.workouts,
                workoutLoader: state => state.workoutModule.workoutLoader,
                isPreviousFolder: state => state.folderModule.isPreviousFolder,
                isAssetFolder: state => state.folderModule.isAssetFolder,
                folderId: state => state.folderModule.folderId,
                folderBreadcrumb: state => state.folderModule.folderBreadcrumb,
            }),

            ...mapGetters({
                companyUserCan: 'authModule/COMPANY_USER_CAN',
            }),
        },

        mounted () {
            const vm = this;

            document.getElementById('workout_index').onclick = function () {
                setTimeout(() => {
                    if (!vm.isMoreOption) {
                        vm.moreOptionDropdown = 0;
                    }
                }, 10);
            }

            if (vm.isPreviousFolder) {
                vm.setDashboardParams({ key: 'folder_id', value: vm.folderId });

                vm.folderParams.archived = vm.isArchived ? 1 : 0,
                vm.isLastBreadcrumb      = true;
                vm.assetFolder           = vm.isAssetFolder ? true : false;
            } else {
                vm.folderParams.archived = vm.params.archived ? 1 : 0;
            }

            vm.folderParams.parent_id = vm.params.folder_id ? vm.params.folder_id : 0;

            vm.setIsPreviousFolder(false);
            vm.getWorkouts(vm.params);
        },

        methods: {
            ...mapActions({
                getWorkouts: 'workoutModule/getWorkouts',
                duplicateWorkout: 'workoutModule/duplicateWorkout',
                deleteWorkout: 'workoutModule/deleteWorkout',
            }),

            ...mapMutations({
                setDashboardParams: 'workoutModule/SET_WORKOUT_DASHBOARD_PARAMS',
                resetDashboardParams: 'workoutModule/RESET_WORKOUT_DASHBOARD_PARAMS',
                setSelectedWorkout: 'workoutModule/SET_SELECTED_WORKOUT',
                setIsArchivedStatus: 'folderModule/SET_IS_ARCHIVED_STATUS',
                setIsPreviousFolder: 'folderModule/SET_IS_PREVIOUS_FOLDER_STATUS',
                setIsAssetFolder: 'folderModule/SET_IS_ASSET_FOLDER_STATUS',
                setFolderBreadcrumb: 'folderModule/SET_FOLDER_BREADCRUMB',
            }),

            moreOption(id) {
                const vm = this;

                vm.isMoreOption       = true;
                vm.moreOptionDropdown = vm.moreOptionDropdown == id ? 0 : id;

                setTimeout(function () {
                    vm.isMoreOption = false;
                }, 100);
            },

            closeBar(){
                this.newPage = false;
            },

            closeSortingDropdown () {
                const vm = this;

                vm.actionList = false;
            },
            closeSortingDropdown2 () {
                const vm = this;

                vm.actionList2 = false;
            },

            togglePerPageFilter () {
                const vm = this;
                vm.actionList         = false;
                vm.bulkActionDropdown = false;
                vm.modeFilterDropdown = false;

                const filter = vm.$refs['workout-per-page-filter'];

                if (filter) {
                    filter.dropdown = !filter.dropdown;
                }
            },

            sortingFilter (filter) {
                const vm      = this;
                const orderBy = filter.field == 'ordering' ? 'order' : filter.field;

                vm.filterTitle      = filter.title;
                vm.selectedWorkouts = [];

                vm.setDashboardParams({ key: 'order_by', value: orderBy });
                vm.setDashboardParams({ key: 'order', value: filter.order });
                vm.getWorkouts(vm.params);
            },

            closePerPageDropdown () {
                const vm = this;

                const filter = vm.$refs['workout-per-page-filter'];

                if (filter) {
                    filter.dropdown = false;
                }
            },

            showAction (ev) {
                const vm = this;

                vm.showContent = ev ? true : false;
            },

            handlePagination (page) {
                const vm = this;

                vm.setDashboardParams({ key: 'page', value: page });

                vm.getWorkouts(vm.params);
            },

            handleDuplicateWorkout (ids) {
                const vm = this;

                if (ids.length) {
                    const options = Helper.swalConfirmOptions('Are you sure?', `You want to duplicate ${ ids.length > 1 ? 'these workouts' : 'this workout'}`);
                          options.preConfirm = function () {
                                                    return vm.duplicateWorkout({ ids }).then((result) => {
                                                        vm.search           = '';
                                                        vm.selectedWorkouts = [];

                                                        vm.setDashboardParams({ key: 'search', value: '' });
                                                        vm.getWorkouts(vm.params);
                                                    });
                                                };

                    Swal.fire(options);
                } else {
                    Toastr.error('Please select at least one workout to duplicate!');
                }
            },

            handleDeleteWorkout (ids) {
                const vm = this;

                if (ids.length) {
                    const options = Helper.swalConfirmOptions('Are you sure?', `This action is permanent. You will not be able to recover this ${ ids.length > 1 ? 'these workouts' : 'this workout'}. ${ ids.length > 1 ? 'Workouts' : 'Workout'} can be added to plans, please type DELETE to remove this ${ ids.length > 1 ? 'these workouts' : 'this workout'}.`, 'Delete');

                    options.confirmButtonColor = '#eb1414';
                    options.input = 'text';
                    options.inputAttributes = { placeholder: 'Enter DELETE' };
                    options.inputValidator  = (input) => {
                                                if (/^[A-Z]/.test(input) == false) {
                                                    return 'Please enter all capital letters';
                                                }

                                                if (input !== 'DELETE') {
                                                    return `Please type DELETE to delete workout`;
                                                }

                                                Swal.enableButtons();
                                            }

                    options.preConfirm = function () {
                                            return vm.deleteWorkout({ ids }).then((result) => {
                                                if (result) {
                                                    vm.refreshWorkout();
                                                }
                                            });
                                        }

                    options.willOpen        = function () {
                                                const button  = Swal.getConfirmButton();
                                                const input   = Swal.getInput()

                                                if (button) {
                                                    button.disabled = true;
                                                }

                                                input.oninput = (event) => {
                                                    const text = event.target.value;

                                                    if (/^[A-Z]/.test(text) == true && text === 'DELETE') {
                                                        Swal.enableButtons();
                                                    } else {
                                                        button.disabled = true;
                                                    }
                                                }
                                            }
                    Swal.fire(options);
                } else {
                    Toastr.error('Please select at least one workout to delete!');
                }
            },

            refreshWorkout () {
                const vm = this;

                // vm.page             = 1;
                // vm.search           = '';
                vm.selectedWorkouts = [];

                vm.setDashboardParams({ key: 'page', value: vm.page });
                vm.setDashboardParams({ key: 'search', value: vm.search });

                vm.getWorkouts(vm.params);
            },

            toggleWorkoutWizard (workout) {
                const vm = this;

                vm.setSelectedWorkout(workout);
                vm.workoutWizard = true;
            },

            handleShareWorkout (workout) {
                const vm = this;

                if (workout.share_count == 0) {
                    vm.shareWorkout  = true;
                    vm.activeWorkout = workout;
                } else if (workout.share_count == 1) {
                    const options = Helper.swalWarningOptions('Attention: Sharing Restrictions', `You are currently attempting to share an asset you do not own. Please note that sharing is exclusively for distributing assets you personally own with other Thrive Coach industry peers to help them leverage Thrive Coach with their clients. <br><br> To allocate this asset to a client or contact, kindly use the 'Assign' feature. This ensures proper authorization and tracking of the assignment within the Thrive Coach system.`);

                    Swal.fire(options);
                }
            },

            handleAssignContacts (ids) {
                const vm = this;

                if (ids.length) {
                    vm.workoutIds    = ids;
                    vm.assignContact = true;
                } else {
                    Toastr.error('Please select at least one workout to assign!');
                }
            },

            handleUnassignContacts (ids) {
                const vm = this;

                if (ids.length) {
                    vm.workoutIds      = ids;
                    vm.unassignContact = true;
                } else {
                    Toastr.error('Please select at least one workout to unassign!');
                }
            },


            refreshFolder (folder) {
                const vm = this;

                vm.folderParams.archived  = folder.archived;
                vm.folderParams.parent_id = folder.parent_id;
                vm.setDashboardParams({ key: 'folder_id', value: folder.parent_id ? folder.parent_id : null });
                vm.setDashboardParams({ key: 'archived', value: folder.archived ? 1 : 0 });

                vm.refreshWorkout();
            },


            updateFolderBreadcrumb (breadcrumb) {
                const vm = this;

                vm.setFolderBreadcrumb({ key: 'playbook', value: breadcrumb ? breadcrumb : [] });
            },

            handleAnalytics (workout) {
                const vm = this;

                vm.activeWorkout = workout;
                vm.showAnalytics = true;
            },

            closeBulkActionDropdown () {
                const vm = this;

                vm.bulkOpt = false;
            },
            closeBulkActionDropdown2() {
                const vm = this;

                vm.bulkOpt2 = false;
            },

            closeTutorialListDropdown(){
                const vm = this;

                vm.tutorialList = false;
            },
        }
    }
</script>

<style scoped>
.acton_header{
    max-width: 1200px;
    width: 100%;
    margin: 0 auto;
    flex-wrap: wrap;
}
.result_wpr.new .actions{
    max-width: 1200px;
    width: 100%;
    margin: 20px auto 10px auto;
}
.tab_sec{
    max-width: 1200px;
    margin: 0 auto 10px auto;
    border: 0;
}
.tab_sec li.active {
    border-radius: 5px;
}
.progress_container{
    max-width: 1170px;
    padding: 0 20px;
    margin: 0 auto;
}
.playbook_list {
    list-style-type: none;
    margin: 0 -20px;
    display: flex;
    flex-wrap: wrap;
}
.playbook_list > li{
    padding: 20px;
    width: 33.333%;
}
.plybook_item{
    box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.08);
    border-radius: 10px;
    line-height: 0;
    position: relative;
    height: 100%;
}
.replace_img{
    position: absolute;
    right: 15px;
    top:15px;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    z-index: 2;
}
.replace_img img{
    max-width: 16px;
    height: auto;
}
.plybook_item .item_img .checkbox{
    position: absolute;
    left: 15px;
    top: 15px;
}
.plybook_item .item_img .checkbox span{
    border-color: #8a8a8a;
}
.plybook_item .item_img .checkbox span i{
    color: #2C3E50;
}
.plybook_item .item_img{
    height: auto;
    position: relative;
    overflow: hidden;
    border-radius: 10px 10px 0 0;
}
.plybook_item .item_img .img{
    height: auto;
    width: 100%;
    object-fit: cover;
}
.plybook_item .item_img .statistics {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    /* background: linear-gradient(to right, rgba(243, 243, 243, 1), rgba(255,255,255,0.8) 70%, rgba(255,255,255,0.5)); */
    background: #f5f5f5;
    padding: 30px 15px 15px;
    opacity: 0;
    transition: all 0.3s ease-in-out;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.plybook_item .item_img .statistics li {
    font-size: 11px;
    line-height: 14px;
    font-weight: 500;
    color: #2C3E50;
    padding: 3px 0;
    text-align: left;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 5px;
}
.plybook_item .item_img .statistics li .pp-label{
    padding: 4px 10px;
    background: #2f7eed;
    color: #fff;
    border-radius: 9px;
    font-size: 9px;
    line-height: 10px;
    font-weight: 400;
    letter-spacing: 0.5px;
    text-transform: uppercase;
}
.plybook_item .item_img .statistics li .ratings{
    display: flex;
    gap: 2px;
}
.plybook_item .item_img .statistics li .ratings span{
    color: #2f7eed;
    font-size: 8px;
}
.plybook_item .item_img .statistics li:first-child {
    margin-bottom: auto;
}
.plybook_item .img {
    width: 100%;
    height: 280px;
    object-fit: cover;
    border-radius: 12px 12px 0 0;
}
.plybook_item .item_ttl {
    font-size: 16px;
    line-height: 22px;
    color: #121525;
    font-weight: 400;
    padding: 15px 20px 0 20px;
    margin-bottom: 15px;
    text-align: left;
    display: block;
    text-decoration: none;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}
.plybook_item .item_info{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px 20px 20px;
}
.plybook_item .item_info .member{
    font-size: 13px;
    line-height: 16px;
    color: #5a5a5a;
    font-weight: 400;
    display: flex;
    align-items: center;
}
.plybook_item .item_info img{
    margin-right: 5px;
    max-height: 16px;
    width: auto;
}
.plybook_item .item_info > ul{
    list-style-type: none;
    display: flex;
    align-items: center;
}
.plybook_item .item_info > ul > li{
    width: 30px;
    height: 30px;
    font-size: 15px;
    color: #2f7eed;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background: #f3f3f3;
    margin-left: 8px;
    cursor: pointer;
    position: relative;
}
.plybook_item .item_info .row_option{
    bottom: -10px;
    top: auto;
    animation: dropMove2 .5s ease-in-out;
}
.plybook_item .item_info .row_option:before{
    bottom: 18px;
    top: auto;
    left: auto;
    transform: rotate(45deg) scale(1);
}
.plybook_item .item_img:hover .statistics, .plybook_item .item_img.active .statistics{
    opacity: 1;
}
@keyframes dropMove2 {
    from {margin-bottom: 10px; opacity: 0;}
    to {margin-bottom: 1px; opacity: 1;}
}

.share_playbook .modal_container{
    height: 640px;
}
.modal.share_playbook .modal_header{
    padding: 20px 30px 0;
}
.modal.share_playbook .modal_body{
    padding: 0 30px;
    flex-direction: column;
}
.share_playbook .tab_row, .report_section .tab_row{
    padding: 20px 0 0 0;
    display: flex;
}
.share_playbook .tab_row li, .report_section .tab_row li{
    font-size: 13px;
    line-height: 15px;
    padding: 8px 20px;
    position: relative;
    cursor: pointer;
}
.share_playbook .tab_row li.active, .report_section .tab_row li.active{
    background: #eaeaea;
    border-radius: 5px 5px 0 0;
}
.share_playbook .tab_wpr{
    width: 100%;
}
.share_playbook .result_wpr {
    width: 100%;
}
table button{
    padding: 3px 10px;
    font-size: 11px;
    line-height: 13px;
    font-weight: 400;
    background: #f2a31e;
    border-radius: 10px;
    color: #fff;
    margin-left: 7px;
}
table button.success{
    background: #00aa14;
}
table button.failed{
    background: #f04438;
}
.modal_footer{
    border-radius: 0 0 12px 12px;
}
.folder_list{
    display: flex;
    margin-top: 20px;
}
.folder_list li{
    width: 33.333%;
}
.sorting_card li{
    border: 1px solid #e9e9e9;
    border-radius: 8px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 20px;
    box-shadow: none;
    transition: all 0.3s ease-in-out;
    overflow: hidden;
}
.sorting_card li img{
    width: 100px;
    height: auto;
    object-fit: cover;
}
.sorting_card .card_details{
    font-size: 14px;
    line-height: 18px;
    font-weight: 500;
    color: #121525;
    padding: 10px 15px;
}
.sorting_card .card_details p{
    font-size: 10px;
    line-height: 13px;
    font-weight: 400;
    color: #5a5a5a;
    margin-top: 3px;
}
.sorting_card li:hover{
    background: #fff;
    box-shadow: 0px 0px 44px rgba(0,0,0,0.13);
}

.result_wpr.small .actions > ul{
    height: 35px;
}
.result_wpr.small .actions > ul li, .result_wpr.small .actions > ul li input{
    padding: 7px 10px;
    font-size: 14px !important;
}
.result_wpr.small .actions > ul li .dropdown_wpr{
    width: 100%;
}
.result_wpr.small table td {
    padding: 12px;
    font-size: 13px;
    line-height: 16px;
}
.result_wpr.small table th{
    padding: 7px 12px;
    font-size: 12px;
    line-height: 16px;
}
.result_wpr.small table td .user_img, .result_wpr.small table td .user_img img{
    width: 30px;
    height: 30px;
}
.result_wpr.small table td h4 {
    font-size: 13px;
    line-height: 16px;
    color: #2f7eed;
    font-weight: 500;
}
.result_wpr table td:not(:first-child, :last-child, :nth-child(2)) *{
    margin: 0 auto;
}
.result_wpr table td .score_circle{
    display: inline-block;
}
.result_wpr table td .score_circle .inner_circle .score{
    font-size: 11px;
    line-height: 13px;
}
.result_wpr table td .score_circle .inner_circle .score span{
    font-size: 9px;
    line-height: 11px;
}
.result_wpr table.standard td:first-child, .result_wpr table.standard th:first-child{
    width: auto;
    text-align: left;
    padding-left: 15px;
}
.row_option li a{
    color: inherit;
    text-decoration: none;
}
.action_list{
    display: flex;
    flex-wrap: wrap;
}
.action_list li{
    margin-right: 5px;
    cursor: pointer;
}
.action_list li i{
    color: #2f7eed;
}
.folders_list{
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin: 0 -5px;
}
.folders_list li{
    padding: 0 5px;
}
.folders_list .folder_item{
    border: 1px solid #E9E9E9;
    padding: 10px 15px;
    border-radius: 9px;
    background: #fff;
    box-shadow: none;
    transition: all 0.3s ease-in-out;
    font-size: 13px;
    line-height: 17px;
    color: #5A5A5A;
    font-weight: 400;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    text-align: center;
    height: 100%;
    cursor: pointer;
}
.folders_list .folder_item img{
    max-width: 30px;
    height: auto;
    margin-right: 10px;
}
.folders_list .folder_item:hover{
    box-shadow: 0px 0px 44px rgba(0,0,0,0.13)
}
.folders_list .folder_item .drp_wrapper{
    display: none;
}
.folders_list .folder_item .dropdown{
    margin-left: 20px;
}
.folders_list .folder_item .dropdown.active .drp_wrapper{
    display: block;
}
.folders_list .folder_item .dropdown.active .drp_wrapper ul{
    padding: 5px 0;
}
.folders_list .folder_item .dropdown.active .drp_wrapper ul li{
    font-size: 10px;
    line-height: 12px;
    padding: 4px 8px;
    text-align: left;
}
.modal.redirect_modal .modal_container{
    height: 420px;
}
.modal.redirect_modal .section_ttl{
    font-size: 16px;
    line-height: 20px;
    color: #5a5a5a;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 1;
    width: 100%;
    margin: 0 0 10px;
}
.modal.redirect_modal .section_ttl span{
    padding: 0 10px;
    background: #fff;
}
.modal.redirect_modal .section_ttl:after{
    content: '';
    position: absolute;
    left: 7px;
    right: 7px;
    top: 50%;
    border-bottom: 1px solid #e9e9e9;
    z-index: -1;
}
.redirect_list{
    display: flex;
    flex-wrap: wrap;
    width: 100%;
}
.redirect_list li{
    width: 33.333%;
    padding: 5px 8px;
}
.redirect_list li label h5{
    font-size: 15px;
    line-height: 20px;
    font-weight: 400;
    margin: 0;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
    padding: 10px;
    cursor: pointer;
    background: rgba(242, 163, 29, 0.05);
    border: 1px solid rgba(242, 163, 29, 0.3);
    color: #757575;
    filter: grayscale(1);
}
.redirect_list li label input[type=radio]:checked ~ h5{
    filter: grayscale(0);
    color: #f2a31d;
}
.redirect_action{
    width: 100%;
    padding: 0 8px;
}
.modal.redirect_modal .action_wpr{
    margin-top: auto;
}
.ques_listing li{
    font-size: 14px;
    line-height: 19px;
    list-style-type:decimal;
    margin-left: 17px;
    padding: 10px 0 10px 5px;
    border-bottom: 1px solid #e9e9e9;
}
.preview_area .close_btn{
    right: 15px;
    top: 15px;
    left: auto;
    position: fixed;
    background: #eaeaea;
    width:25px;
    height: 25px;
    border-radius: 50%;
    cursor: pointer;
}
.modal .preview_area.show_sidebar{
    left: 0;
    width: 100%;
}
.header_filter{
    position: fixed;
    top: 0;
    bottom: 0;
    background: #fff;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.05);
    width: 250px;
    overflow-y: auto;
    right: -250px;
    z-index: 12;
    transition: all 0.3s ease-in-out;
    text-align: left;
}
.header_filter.show{
    right: 0;
}
.header_filter .close_btn{
    position: absolute;
    right: 20px;
    top: 20px;
    width: 20px;
    height: 20px;
    font-size: 10px;
    border-radius: 50%;
    background: #eaeaea;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #5a5a5a;
    cursor: pointer;
}
.header_filter .top_area{
    padding: 20px;
}
.header_filter .top_area h3{
    font-size: 17px;
    line-height: 22px;
    font-weight: 500;
    color: #121525;
    margin-bottom: 15px;
}
.header_filter .search_area {
    padding: 0 31px 0 0;
    border-radius: 17px;
    background: #f5f5f5;
    position: relative;
    margin: 15px 0;
}
.header_filter .search_area input[type=text] {
    font-size: 11px;
    height: 30px;
    width: 100%;
    background: transparent;
    padding: 0 0 0 15px;
    transition: all 0.3s ease-in-out;
}
.header_filter .search_area .search_btn {
    position: absolute;
    right: 0;
    top: 0;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    font-size: 11px;
    color: #121525;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
.header_filter .top_area ul {
    list-style-type: none;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-top: 5px;
}
.header_filter .top_area ul li {
    height: 30px;
    width: 30px;
    background: #2f7eed;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.02);
    margin-right: 6px;
}
.header_filter .top_area ul li img {
    max-width: 16px;
    height: auto;
    filter: brightness(100);
}
.header_filter .action_area{
    padding: 20px;
    border-top: 1px solid #e9e9e9;
}
.header_filter .action_area ul li .checkbox h5 {
    font-size: 13px;
    line-height: 16px;
    font-weight: 400;
    color: #121525;
    margin: 5px 0;
}
.header_filter .action_area > ul > li {
    font-size: 13px;
    line-height: 18px;
    font-weight: 400;
    color: #5a5a5a;
    text-align: left;
    /* background: #f5f5f5; */
    display: flex;
    align-items: center;
    position: relative;
    cursor: pointer;
    margin-bottom: 10px;
}
.header_filter .action_area > ul > li.optionDrops{
    padding: 7px 15px;
    border: 1px solid #e9e9e9;
    border-radius: 20px;
}
.header_filter .action_area > ul > li > i{
    margin-left: auto;
}
.header_filter .action_area .dropdown_wpr ul{
    max-height: 250px;
    overflow-y: auto;
}
.header_filter .action_area .dropdown_wpr ul li{
    border: 0;
}
.header_filter .action_area .dropdown_wpr ul li.active{
    background: #f5f5f5;
}
.result_wpr.new .actions > ul li.filter_btn{
    background: #fff;
    width: 30px;
    height: 30px;
    border-radius: 3px;
    padding: 4px 6px;
    display: none;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    cursor: pointer;
}
.filter_btn span{
    height: 2px;
    width: 100%;
    border-radius: 2px;
    background: #2f7eed;
    margin: 2px 0;
}
.filter_btn span:nth-child(2){
    width: 60%;
}
/* @media(max-width: 1199px){
    .acton_header{
        flex-wrap: wrap;
    }
    .acton_header .left_section, .acton_header .right_section{
        width: 100%;
    }
    .acton_header .right_section{
        margin-top: 25px;
    }
    .acton_header .left_section .search_area, .acton_header .right_section > ul > li.add_btn{
        margin-left: auto;
    }
    .acton_header .search_area input, .acton_header .search_area .search_btn{
        height: 30px;
    }
} */
@media(max-width: 1199px){
    .acton_header .search_area input{
        width: 200px;
        height: 30px;
    }
    .acton_header .search_area .search_btn{
        height: 30px;
    }
    .acton_header .left_section .search_area{
        display: none;
    }
    .acton_header .right_section > ul > li:not(.add_btn){
        display: none;
    }
    .result_wpr.new .actions > ul > li:not(.tutorial_btn, .sort_list, .filter_btn){
        display: none;
    }
    .result_wpr.new .actions > ul li.filter_btn{
        display: flex;
    }
}
/* @media(max-width: 600px){
    .acton_header {
        gap: 0;
    }
    .acton_header .search_area input{
        width: 170px;
    }
    .acton_header .right_section, .result_wpr.new .actions{
        margin-top: 15px;
    }
    .result_wpr.new .actions > ul li button.video_btn{
        margin-left: 10px;
    }
    .result_wpr.new .actions > ul.left, .result_wpr.new .actions > ul.right{
        width: calc(100% + 14px);
    }
    .result_wpr.new .actions > ul.left li.tutorial_btn, .result_wpr.new .actions > ul.right li.sort_list{
        margin-left: auto;
    }
    .tutorial_btn .dropdown_wpr{
        right: 1px;
        left: auto;
    }
} */

@media(min-width: 1200px){
    .header_filter{
        display: none;
    }
}
</style>